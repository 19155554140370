/* You can add global styles to this file, and also import other style files */
$mainColor: rgba(87, 121, 236, 0.65);

a {
  text-decoration: none;
}

html,
body {
  height: 100%;
}

.container-fluid .row {
  width: 100%;
  margin: auto;
}

/*===========================Wallets========================*/
.USD-currency {
  background-color: rgb(245, 100, 43);
}

.EGP-currency {
  background-color: rgb(238, 32, 17);
}

.EUR-currency {
  background-color: rgb(48, 233, 224);
}

.SAR-currency {
  background-color: rgb(42, 10, 158);
}

.AED-currency {
  background-color: rgb(209, 40, 138);
}

.USD-currency,
.EGP-currency,
.SAR-currency,
.AED-currency {
  color: #fff;
}

/*===========================Order Status========================*/
.badge {
  color: #fff;
  padding: 8px 14px;
  text-transform: capitalize;
}

.badge-pending {
  background-color: rgb(80, 74, 74);
}

// .badge-Processing,
.badge-approved {
  background-color: green;
}

.badge-onHold {
  background-color: rgb(184, 184, 53);
}

.badge-failed,
.badge-rejected {
  background-color: red;
}

.badge-compeleted,
.badge-paid {
  background-color: green;
}

.badge-processing {
  background-color: orange;
}


.badge-accepted {
  background-color: green;
}

.badge-canceled {
  background-color: gray;
}

.badge-returned {
  background-color: rgb(175, 10, 120);
}

.badge-refunded {
  background-color: rgb(175, 10, 120);
}

.badge-partialdelivered {
  background-color: blue;
}

/*===========================Text========================*/
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.ellipsis-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  line-height: 15px;
  max-width: 400px;
  padding: 0px 10px;
}

/*======================Shared Checkbox===================*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-disable-cursor {
  pointer-events: none;
  opacity: 0.7;
}

.shared-border {
  border: 2px solid $mainColor;
}

p-toast {
  z-index: 999999999999;
}

.search-button {
  background: #27a842;
}

.clear-button {
  background: rgb(16, 118, 227);
}

.search-button,
.clear-button {
  width: 111px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.filter-container {
  display: flex;
  align-items: center;

  input {
    width: 170px;
  }
}



table {
  width: 100%;
  thead {
    tr {
      th {
        padding: 1rem;
        background-color: #f8f9fa;
        font-weight: 700;
        color: #374151;
        font-size: 17px !important;
        text-wrap: nowrap;
      }
    }
  }
  tbody {
    tr {
      td {
        text-wrap: nowrap;
        padding: 1rem;
        color: #374151;
        border-bottom: #eee solid 1px;
      }
    }
  }
} 

// st-editor {
//   .st-area {
//     img {
//       max-width: 500px;
//     }
//   }
// }


// ! ===============================   >>>  tooltip   <<<  =================================
.tooltip {
  position: absolute;
  text-wrap: nowrap;
  border-radius: 5px;
  padding: 5px 10px;
  opacity: 0;
  color: #fff;
  background-color: #000;
  transition: .2s !important;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .appear-tooltip {
    opacity: 1;
  }
  .disappear-tooltip {
    opacity: 0;
  }

  @media (width <= 768px) {
    .tooltip {
      display: none;
    }
  }

  .position-top {
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
  .position-bottom {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
  .position-left {
    top: 50%;
    right: calc(100% + 5px);
    transform: translateY(-50%);
  }
  .position-right {
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
  }


  .p-table {
    td {
      font-size: 15px;
    }
  }

// ! ===================================================================================